
import CloudFun, { Condition, defineComponent, Operator, reactive, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import { VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    var printColumns = [
      { field: "Number" },
      { field: "StockDate" },
      { field: "Warehouse.Name" },
      { field: "Submitter.Name" },
      { field: "Amount" },
      { field: "Status" },
    ];

    const gridOptions = reactive<GridOptions>({
      id: "supplier",
      title: "庫存調整",
      multiselect: false,
      toolbarConfig: { custom: true, refresh: true },
      printConfig: { sheetName: "庫存調整清單", columns: printColumns, modes: ["current", "selected", "all"] },
      exportConfig: { filename: "庫存調整清單", type: "csv", types: ["html", "csv"], mode: "all", modes: ["current", "selected", "all"], columns: printColumns },
      columns: [
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, width: 100, sortable: true },
        {
          field: 'AdjustDate', title: '調整日期', showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : ""
        },
        { field: 'Warehouse.Name', title: '倉庫', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Submitter.Name', title: '提交人員', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Status", title: "狀態", showHeaderOverflow: true, showOverflow: true, width: 100, resizable: false, formatter: ({ cellValue }) => model ? Object.values(model.enums.AdjustInventoryBillStatus).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      decideRowOperable: (row, operation) => row.Status < 30 || operation === 'read',
      promises: {
        query: model ? (params) => {
          params.sortings = params.sortings || [];
          params.sortings.push({ column: 'AdjustDate', order: 1 });
          return model.dispatch("adjustInventoryBill/query", params);
        } : undefined,
        queryAll: model ? () => model.dispatch("adjustInventoryBill/query", { sortings: [{ column: 'AdjustDate', order: 1 }] }) : undefined,
        save: model ? (params) => model.dispatch("adjustInventoryBill/save", params) : undefined,
      },
      modalConfig: { width: 800, showFooter: true },
    });

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } } },
        { field: "Time", title: "日期", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "請輸入時間" } } },
        { field: "WarehouseId", title: "倉庫", span: 12, slots: { default: "column-warehouse-id" } },
        { field: "AdjustDate", title: "調整日期", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.AdjustInventoryBillStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Submitter.Name', title: '提交人員', span: 12, itemRender: { name: '$input', props: { disabled: true } } },
      ],
      rules: {
        Time: [{ required: true }],
        AdjustDate: [{ required: true }],
        WarehouseId: [{ required: true }]
      }
    };

    const warehouseSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇倉庫',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("warehouse/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("warehouse/query", params) // eslint-disable-line
      },
    }

    const productSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇產品',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true },
        { field: "Barcode", title: "條碼", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
        { field: "Brand.Name", title: "品牌", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
      ],
      promises: {
        find: (value) => model!.dispatch("product/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("product/query", params) // eslint-disable-line
      },
    }

    const detailGrid = ref<any>({});
    const detailGridOptions = reactive<GridOptions>({
      round: true,
      border: true,
      stripe: true,
      autoResize: true,
      sortConfig: { defaultSort: { field: "CreatedTime", order: "desc" } },
      mode: 'inline',
      multiselect: false,
      columns: [
        {
          field: "ProductId", title: "產品", sortable: true,
          slots: { default: "column-product-id" },
        },
        {
          field: "Quantity", title: "數量", sortable: true, width: "100", align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'number', min: 0 },
          }
        },
      ],
      editRules: {
        ProductId: [{ required: true, message: "未選擇產品" }],
        Price: [
          { required: true, message: '未輸入單價' },
          { min: 0, message: '單價不得為負數' }
        ],
        Quantity: [
          { required: true, message: '未輸入數量' },
          { min: 0, message: '數量需為正值' }
        ],
      },
      promises: {
        query: model ? (params) => { params.condition = new Condition("AdjustInventoryBillId", Operator.Equal, grid.value.editingRow?.Id || 0).and(params.condition!); return model.dispatch("adjustInventoryBillItem/query", params); } : undefined,
        queryAll: model ? () => model.dispatch("adjustInventoryBillItem/query") : undefined,
        save: model ? (params) => model.dispatch("adjustInventoryBillItem/save", params).then(async () => { grid.value.editingRow.Amount = (await model.dispatch('adjustInventoryBill/find', grid.value.editingRow.Id)).Amount; grid.value.refresh(); }) : undefined,
      },
      modalConfig: { height: "auto" },
    });

    return {
      grid,
      gridOptions,
      formOptions,
      warehouseSelectOptions,
      productSelectOptions,
      detailGrid,
      detailGridOptions,
    };
  },
  methods: {
    hideBatchDropDown () {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onAddItem(masterRow: any) {
      if (!masterRow.Id) {
        if (!confirm("新增項目須先建立此張調整單，是否同意?")) return;
        try {
          const bill = await this.$model.dispatch("adjustInventoryBill/insert", masterRow);
          Object.assign(masterRow, bill);
        } catch (e: any) {
          this.$send('error', e);
        }
      }
      this.detailGrid.addNewRow({
        AdjustInventoryBillId: this.grid.editingRow.Id,
        Quantity: 1,
      });
    },
  }
});
